/**
 * Fonts
 */
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.otf') format('opentype'), url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Light.eot');
  src: url('../fonts/ProximaNova-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Light.woff') format('woff'), url('../fonts/ProximaNova-Light.otf') format('opentype'), url('../fonts/ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Light-Italic.eot');
  src: url('../fonts/ProximaNova-Light-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Light-Italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
  font-display: auto;
}
/*@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Regular-Italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
  font-display: auto;
}*/
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.otf') format('opentype'), url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'ProximaNova';
  src: url('../fonts/ProximaNova-Extrabld.eot');
  src: url('../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Extrabld.woff') format('woff'), url('../fonts/ProximaNova-Extrabld.otf') format('opentype'), url('../fonts/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: auto;
}
/**
 * Fonts End
 */
